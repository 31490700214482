"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.init = init;
var _bsCommon = require("bs-common");
var _i18next = _interopRequireDefault(require("i18next"));
var _reactI18next = require("react-i18next");
var _common = _interopRequireDefault(require("./en/common.json"));
var _common2 = _interopRequireDefault(require("./zh/common.json"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var LangCacheKey = '_lang_';

// 初始化
function init() {
  var langName = localStorage.getItem(LangCacheKey);
  if (langName != 'zh' && langName != 'en') {
    _bsCommon.lang.change('zh');
  } else {
    _bsCommon.lang.change(langName);
  }
  _i18next["default"].use(_reactI18next.initReactI18next).init({
    resources: {
      en: {
        translation: _common["default"]
      },
      zh: {
        translation: _common2["default"]
      }
    },
    lng: _bsCommon.lang.lang,
    fallbackLng: 'zh',
    interpolation: {
      escapeValue: false
    }
  });
  _bsCommon.lang.t = function (name) {
    return _i18next["default"].t(name);
  };
  var oldchange = _bsCommon.lang.change;
  _bsCommon.lang.change = function (langName) {
    localStorage.setItem(LangCacheKey, langName);
    oldchange(langName);
  };
}