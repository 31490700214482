"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.slice = exports["default"] = exports.actions = void 0;
var _TempConsignmentApi = _interopRequireDefault(require("../apis/TempConsignmentApi"));
var _bsCommon = require("bs-common");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var slice = (0, _bsCommon.bsCreateSlick)('tempConsignment', _TempConsignmentApi["default"]);
exports.slice = slice;
var actions = slice.actions;
exports.actions = actions;
var _default = slice.reducer;
exports["default"] = _default;