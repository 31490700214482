"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.createClearReduxDatasAction = void 0;
var _toolkit = require("@reduxjs/toolkit");
var _userSlice = _interopRequireDefault(require("./userSlice"));
var _consignmentSlice = _interopRequireDefault(require("./consignmentSlice"));
var _tempConsignmentSlice = _interopRequireDefault(require("./tempConsignmentSlice"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var ClearReduxDatasActionType = 'ClearReduxDatas';
var createClearReduxDatasAction = function createClearReduxDatasAction() {
  return {
    type: ClearReduxDatasActionType
  };
};
exports.createClearReduxDatasAction = createClearReduxDatasAction;
function reducerPack(reducer) {
  return function (state, actions) {
    if (actions.type === ClearReduxDatasActionType) {
      return {
        page: -1,
        pageSize: 30,
        filter: {},
        sortField: undefined,
        sortDirection: 'desc',
        total: 0,
        datas: []
      };
    }
    return reducer(state, actions);
  };
}
var _default = (0, _toolkit.configureStore)({
  reducer: {
    user: reducerPack(_userSlice["default"]),
    consignment: reducerPack(_consignmentSlice["default"]),
    tempConsignment: reducerPack(_tempConsignmentSlice["default"])
  }
});
exports["default"] = _default;