"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _locales = require("./locales");
var _bsCommon = require("bs-common");
var _default = function _default() {
  (0, _locales.init)();
  _bsCommon.token.init();
};
exports["default"] = _default;