"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Enums = _interopRequireDefault(require("./Enums"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var _default = {
  Status: [{
    label: 'ACTIVE',
    value: _Enums["default"].Status.ACTIVE
  }, {
    label: 'INACTIVE',
    value: _Enums["default"].Status.INACTIVE
  }],
  Role: [{
    label: 'ROLE_SUPER',
    value: _Enums["default"].Role.ROLE_SUPER
  }, {
    label: 'ROLE_ADMIN',
    value: _Enums["default"].Role.ROLE_ADMIN
  }, {
    label: 'ROLE_MASTER',
    value: _Enums["default"].Role.ROLE_MASTER
  }, {
    label: 'ROLE_SUB',
    value: _Enums["default"].Role.ROLE_SUB
  }],
  TempConsignmentStatus: [{
    label: 'WAIT_PREPROCESS',
    value: _Enums["default"].TempConsignmentStatus.WAIT_PREPROCESS
  }, {
    label: 'PREPROCESS_SUCCESS',
    value: _Enums["default"].TempConsignmentStatus.PREPROCESS_SUCCESS
  },
  // { label: 'FINISHED', value: Enums.TempConsignmentStatus.FINISHED },
  {
    label: 'PREPROCESS_FAILURE',
    value: _Enums["default"].TempConsignmentStatus.PREPROCESS_FAILURE
  }, {
    label: 'CANCELLED',
    value: _Enums["default"].TempConsignmentStatus.CANCELLED
  }],
  ConsignmentStatus: [{
    label: 'WAIT_INTEGRATE',
    value: _Enums["default"].ConsignmentStatus.WAIT_INTEGRATE
  }, {
    label: 'INTEGRATED',
    value: _Enums["default"].ConsignmentStatus.INTEGRATED
  }, {
    label: 'FINISHED',
    value: _Enums["default"].ConsignmentStatus.FINISHED
  }, {
    label: 'CANCELLED',
    value: _Enums["default"].ConsignmentStatus.CANCELLED
  }],
  PrintedStatus: [{
    label: 'yes',
    value: _Enums["default"].PrintedStatus.PRINTED
  }, {
    label: 'no',
    value: _Enums["default"].PrintedStatus.NOT_PRINTED
  }],
  IntegrateStatus: [{
    label: 'FAILED',
    value: _Enums["default"].IntegrateStatus.FAILED
  }, {
    label: 'SUCCESS',
    value: _Enums["default"].IntegrateStatus.SUCCESS
  }]
};
exports["default"] = _default;