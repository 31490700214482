"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  Status: {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE'
  },
  Role: {
    ROLE_SUPER: 'ROLE_SUPER',
    ROLE_ADMIN: 'ROLE_ADMIN',
    ROLE_MASTER: 'ROLE_MASTER',
    ROLE_SUB: 'ROLE_SUB'
  },
  TempConsignmentStatus: {
    WAIT_PREPROCESS: 'WAIT_PREPROCESS',
    PREPROCESS_SUCCESS: 'PREPROCESS_SUCCESS',
    // FINISHED: 'FINISHED',
    PREPROCESS_FAILURE: 'PREPROCESS_FAILURE',
    CANCELLED: 'CANCELLED'
  },
  ConsignmentStatus: {
    WAIT_INTEGRATE: 'WAIT_INTEGRATE',
    INTEGRATED: 'INTEGRATED',
    FINISHED: 'FINISHED',
    CANCELLED: 'CANCELLED'
  },
  PrintedStatus: {
    PRINTED: 'PRINTED',
    NOT_PRINTED: 'NOT_PRINTED'
  },
  IntegrateStatus: {
    FAILED: "FAILED",
    SUCCESS: "SUCCESS"
  }
};
exports["default"] = _default;