import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { store } from 'bs-oms-core';
import { lang } from 'bs-common';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import './index.css';
import { Layout, Login } from 'bs-oms-layout';

import { ConfigProvider, theme } from 'antd';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/en-gb';
import zhCN from 'antd/locale/zh_CN';
import enGB from 'antd/locale/en_GB';
import 'antd/dist/reset.css';

// 初始化程序
import { initFun as masterInit } from 'bs-oms-master';
import { initFun as subInit } from 'bs-oms-sub';
import { initFun as adminInit } from 'bs-oms-admin';
import { initFun as layoutInit } from 'bs-oms-layout';
import { initFun as coreInit } from 'bs-oms-core';

var isInit = false;
function initApp() {
  if (isInit) {
    return;
  }
  isInit = true;

  coreInit();
  layoutInit();
  masterInit();
  subInit();
  adminInit();
}

const Home = () => <Navigate to='/login' replace />

class App extends React.Component {
  configLocale;
  constructor(props) {
    super(props);
    initApp();

    // 根据当前语言调整antd的语言
    if (lang.lang === 'zh') {
      this.configLocale = zhCN;
      dayjs.locale('zh-cn');
    }
    else {
      this.configLocale = enGB;
      dayjs.locale('en-gb');
    }
  }

  render(): React.ReactNode {
    return <ConfigProvider locale={this.configLocale}
      theme={{
        algorithm: theme.compactAlgorithm
      }}
    >
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/back/:backstage/*" element={<Layout />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();